#iframe .layer-legend,
#iframe .header,
#iframe .branding,
#iframe #print-map,
#iframe .onload-branding-modal,
#iframe .grazing-modal {
  display: none !important;
}

#iframe .modal-backdrop.show {
  opacity: 0;
}

#iframe .content-container,
#iframe .tab-content,
#iframe .tab-pane {
  height: calc(100vh - 2rem);
}

#iframe .table-responsive {
  height: calc(100vh - 5rem) !important;
}

#iframe .app-container {
  grid-template-rows: 2rem 1fr;
  grid-template-areas: "tab-bar" "content";
}

#iframe .tab-list-items {
  height: 2rem;
}

#iframe .nav-tabs {
  height: 2rem;
}

#iframe .nav-tabs .nav-link {
  line-height: 1rem;
}
