.satelliteBar {
  fill: #007bbc;
  clip-path: url(#clip);
}

.predictedBar {
  fill: #f2a0ee;
  clip-path: url(#clip);
}

.editedBar {
  fill: #203a70;
  clip-path: url(#clip);
}
.subBar {
  fill: gray;
  opacity: 0.5;
}

.axis {
  font-family: "Booster FY";
  font-size: 15px;
}
.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.brush .extent {
  stroke: #fff;
  fill: #007bbc;
  fill-opacity: 0.25;
  shape-rendering: crispEdges;
}

rect.mover {
  stroke: red;
  stroke-opacity: 0.1;
  fill: lightSteelBlue;
  fill-opacity: 0.5;
}

.yAxis-text {
  font-size: 15px;
  text-anchor: "middle";
}
