@media print {
  @page {
    background-color: white !important;
    size: 210mm 297mm !important;
    margin: 0 important;
  }

  a[href]:after {
    content: none !important;
  }
  img[src]:after {
    content: none !important;
  }

  html,
  body {
    -webkit-print-color-adjust: exact !important;
    background-color: white !important;
    min-width: 210mm !important;
    width: 210mm !important;
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    position: relative !important;
    float: none !important;
    background-color: white !important;
    font-size: 9pt;
  }

  /* Reset behaviour of block level elements */
  #root,
  .container,
  .row,
  .branding,
  .print-container {
    min-width: 100% !important;
    width: 100% !important;
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    position: relative !important;
    float: none !important;
    background-color: white !important;
  }

  .print-container {
    z-index: 1;
  }

  /* Hide unwanted components */
  .paddock-tab-name,
  .paddock-col.d-lg-block,
  .layers-icon,
  .map-column .nav,
  .paddock-col,
  .download-buttons,
  .average-heading,
  .layers-menu,
  .app-container,
  .layer-print-button,
  #chart {
    display: none !important;
  }

  .row {
    width: 100%;
    display: block !important;
  }

  /* enforce height cascade */
  #root {
    height: auto !important;
    min-height: 100% !important;
  }

  #root > div,
  .tab-content,
  .tab-pane,
  .table-responsive {
    height: auto !important;
    min-height: auto !important;
    background-color: white !important;
  }

  /* Header component */
  .header {
    grid-area: header;
    height: 1.25cm;
    font-size: 21pt;
    display: block !important;
  }
  .header div:not(.average-heading) {
    display: inline !important;
    padding: 0 !important;
    font-size: 14pt;
  }

  .average-heading {
    float: right;
    font-size: 12pt;
    text-align: right;
    padding-right: 0 !important;
    max-width: 10cm !important;
  }

  .average-heading.d-lg-block {
    display: inline !important;
  }

  .average-heading strong {
    font-size: 16pt;
  }

  .name-heading {
    margin-right: 0.5cm;
  }

  /* Branding component */
  .navbar {
    display: flex !important;
    height: 1.5cm;
    padding: 0 !important;
  }

  .branding {
    display: block !important;
    height: 1.5cm;
    grid-area: branding;
    width: 100%;
    padding: 0 !important;
  }
  .branding div {
    padding: 0 !important;
  }
  .branding svg {
    height: 0.75cm;
    margin-top: 0.75rem;
  }

  .branding .order-2 {
    text-align: right;
  }

  /* Map Component */
  .pasture_center.col-lg-12,
  #print-map {
    width: 100%;
    background-color: #eee;
    position: relative;
    z-index: 0 !important;
    margin-left: -7px;
    height: 33cm !important;
    page-break-inside: avoid;
  }

  .map-layer-switch-container {
    width: 100% !important;
    position: relative;
  }

  .left-label {
    float: left;
  }
  .right-label {
    float: right;
  }
  .map-layer-color-palette {
    clear: both;
  }

  .map-column.col-lg-9 {
    width: 100% !important;
    display: block;
    position: relative;
    flex: initial;
    max-width: 100% !important;
    padding: 0 !important;
  }

  /* Set behaviour for the tab content panes */
  .tab-content,
  .tab-content > .tab-pane {
    display: block !important;
    width: 100%;
    padding: 0 !important;
  }

  .tab-pane {
    clear: both;
    float: none;
    width: auto;
    overflow: auto !important;
  }

  .tab-pane:nth-child(2) {
    min-height: 1400px !important;
    page-break-inside: avoid;
  }

  /* Style the paddock list */
  .table {
    break-before: always;
    page-break-before: always;
    width: 100mm !important;
    -fs-table-paginate: paginate;
  }

  .table,
  .table tr,
  .table td,
  .table th {
    color: black !important;
    font-size: 16pt !important;
    border: thin solid #ccc !important;
    border-collapse: collapse;
  }

  /* reveal graph */
  #chart,
  #chart > svg {
    overflow: initial !important;
  }

  /* hide unwanted components*/
  .mover,
  .subBar,
  .grazing-target,
  .normal-chart {
    display: none;
  }

  /*reveal print version of chart*/
  .print-chart {
    display: block !important;
  }
}
