#map {
  z-index: 0;
}

#print-map {
  z-index: -1;
}

.leaflet-tile-container img {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
}

/* For mobile only */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  #map {
    margin-left: 15px;
  }
}