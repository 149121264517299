thead {
  background-color: #001e62;
}

.table-striped tbody tr {
  background-color: #edf3fb !important;
  border: none;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: white !important;
}

.table td,
.table th {
  border: none !important;
  font-size: 0.875rem !important;
}

.table tbody tr td:first-child {
  padding-left: 1rem;
}
.table tr td:last-child,
.table tr th:last-child {
  text-align: right;
}

.table th {
  color: white;
}

.table tbody td {
  color: #001e62;
}

.paddock-tab-name input {
  border-color: #001e62;
  border-left: none;
  padding-left: 0.25rem;
  color: #001e62 !important;
}

.paddock-tab-name {
  padding: 0.5rem 0.5rem;
  height: 3rem;
}

.paddock-tab-name input:focus {
  border-color: #001e62;
  box-shadow: none;
}

.paddock-tab-name .search-icon {
  width: 2rem;
  height: 2rem;
  background-color: white;
  padding: 0.35rem 0.35rem 0.35rem 0.5rem;
  border: thin solid #001e62;
  border-right: none;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.paddock-tab-name input,
.paddock-tab-name button {
  text-overflow: ellipsis;
  font-size: 0.875rem !important;
  height: 2rem;
}

.paddock-tab-name button {
  border: none;
}

.grazed-button {
  padding: 0px 6px !important;
  max-height: 24px;
  color: #203a70 !important;
  background-color: #ffffff !important;
  border-color: #203a70 !important;
}

.sort-icon {
  color: white;
  margin-left: 0.5rem;
}

.satelliteCover {
  color: #203a70 !important;
}
.predictedCover {
  color: #f2a0ee !important;
}
.cover-data {
  max-width: 110px;
  text-align: center;
}
.cover-data input {
  text-align: center;
  max-width: 110px;
  max-height: 24px;
  font-size: 0.875rem;
  padding: unset;
  margin-top: -2px;
}

.cover-data .validation-error {
  border: 1px solid red !important;
}

tr#updated-paddock-row {
  background-color: #cbdbef !important;
}

#updated-paddock-row td {
  color: #203a70 !important;
  font-weight: bold;
}

.cover-data div:hover .error-tooltip {
  visibility: visible;
}

.error-tooltip {
  visibility: hidden;
  position: absolute;
  text-align: center;
  width: auto;
  padding: 5px 3px;
  border-radius: 6px;
  color: white;
  margin-left: -80px;
  background-color: black;
  z-index: 1;
}
