html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  color: #001e62 !important;
}

#root,
#root > div {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS */
.logo_image {
  width: fit-content;
  max-height: 1.8125rem;
  max-width: 7rem;
}

.space_image {
  width: inherit;
  max-width: 7rem;
}

.menu-button {
  border-radius: 50% !important;
  width: 4rem;
  padding: 0px !important;
}

.menu-button:hover {
  background-color: white !important;
}

.menu-button svg {
  height: 1.6rem;
}

.name-heading {
  color: #001e62;
  font-size: 1.5rem;
}

.download-buttons {
  display: flex;
  justify-content: flex-start;
  border-left: thin solid #cbdbef;
  margin-right: 5px;
}

.download-buttons button {
  background-color: #001e62;
  border-color: #001e62;
  font-size: 0.75rem !important;
  color: white;
  padding: 0;
}

.download-buttons button:hover {
  background-color: #001a55;
  border-color: transparent;
}

.download-buttons button[disabled],
.download-buttons button[disabled]:hover {
  background-color: #6c757d;
  border-color: #6c757d;
}

.download-buttons a {
  color: transparent;
  display: block;
  height: 100%;
  line-height: 2rem;
}

.download-buttons a:hover,
.download-buttons a:link {
  color: transparent;
}
.download-buttons .download-button {
  border-radius: 0.25rem !important;
  width: 3.5rem;
}
.download-icon {
  height: 0.75rem;
  margin-right: 0.25rem;
  margin-top: -0.25rem;
}

.pasture_center div {
  width: 100%;
}

.green-block {
  height: 4vw !important;
  border: 1px solid black !important;
  background-color: rgb(16, 143, 16) !important;
}

.black-block {
  height: 4vw !important;
  border: 1px solid black !important;
  background-color: black !important;
}

.paddock-tab-name {
  padding: 0.5rem 0.5rem;
  height: 3rem;
}

.paddock-tab-name input:focus {
  border-color: #001e62;
  box-shadow: none;
}

.paddock-tab-name .search-icon {
  width: 2rem;
  height: 2rem;
  background-color: #cbdbef;
  padding: 0.3rem;
}

.paddock-tab-name input,
.paddock-tab-name button {
  text-overflow: ellipsis;
  font-size: 0.75rem;
}

.sort-icon {
  color: #001e62;
  margin-left: 0.5rem;
}

.nav {
  height: 3rem;
  overflow: hidden;
}

.nav-tabs {
  height: 3rem;
  border-bottom: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;
  position: relative;
  z-index: 88;
}

.tab-list-items {
  height: 3rem;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  border-right: thin solid #cbdbef !important;
  border-bottom: thin solid #cbdbef !important;
  height: 100%;
  color: #001e62;
  border-radius: 0 !important;
  background-color: white;
  line-height: 2rem;
}

.nav-tabs .nav-link.active {
  border-radius: 0 !important;
  color: #001e62 !important;
  font-weight: bolder;
  background-color: #edf3fa !important;
  box-shadow: inset 0 -2px 0 #edf3fa,
    inset rgba(25, 63, 120, 0.1) 0px 0px 2px 1px;
  border-bottom: 0 !important;
}

.nav-tabs .nav-link:last-child {
  border-left: none;
}

.paddock-col {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.tab-list-items {
  flex: 0.5;
  text-align: center;
  font-size: 0.75rem;
}

.search-button {
  background-color: #001e62 !important;
}

.branding-align-right {
  text-align: right;
}

.branding-align-right button {
  margin-left: 1rem;
  background-color: #001e62 !important;
  width: fit-content;
  padding: 0.2rem 0.75rem !important;
}

.branding-print-button {
  max-width: 9rem;
}

.branding-csv-button {
  max-width: 5.5rem;
}

.app-container {
  max-width: 100% !important;
  height: 100%;
  display: grid;
  background-color: #fbfcff;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas: "branding" "header" "content";
  grid-template-rows: 3.5rem 3.5rem 1fr;
  overflow: hidden;
  z-index: 99;
}

.print-container {
  z-index: -1;
  position: fixed;
}

.header {
  grid-area: header;
  border-top: solid thin #cbdbef;
  border-bottom: solid thin #cbdbef;
  background-color: white;
  padding: 0.5rem;
  height: 3.5rem;
}

.branding {
  grid-area: branding;
  display: inline !important;
  background-color: white;
  position: relative;
  z-index: 99;
}

.branding .navbar {
  padding: 0px;
  flex-wrap: nowrap;
  align-items: flex-start;
  height: 100%;
}

.branding .navbar [class*="col"] {
  padding: 0.8rem;
  height: 100%;
}

button {
  width: 100%;
  height: 100%;
}

.average-heading {
  color: #001e62;
  font-size: 0.875rem;
}

.average-heading p {
  line-height: 2.5rem;
}

.overlay-menu {
  width: 20rem;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: rgba(0, 30, 98, 1);
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.2) -3px 2px 3px;
  padding: 0.5rem;
}

.overlay-menu span {
  color: white !important;
}

.overlay-menu .close {
  color: white !important;
  border-color: none !important;
}

.overlay-menu .nav-item {
  color: white;
  font-size: 1.125rem;
  padding: 1rem;
  border-bottom: thin solid #00267c;
}

.overlay-menu a {
  float: right;
  color: white !important;
}

.farm-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-container,
.tab-content,
.tab-pane {
  height: calc(100vh - 10rem);
  background-color: #edf3fa;
}

.tab-data .tab-pane {
  overflow: hidden;
}

/* just for Desktop */
@media only screen and (min-width: 1025px) {
  body {
    font-size: 0.75rem !important;
  }

  .map-column {
    padding-left: 0px !important;
  }

  .average-heading {
    font-size: 1rem;
  }

  .average-heading strong {
    font-size: 1.5rem;
  }

  .content-container {
    height: calc(100vh - 10rem);
  }

  .map-column {
    padding-right: 0 !important;
  }
}

/* For tablets and Desktops */

@media only screen and (min-width: 768px) {
  .name-heading {
    padding-left: 0.5rem !important;
  }

  .average-heading {
    padding-right: 0.5rem !important;
    text-align: right;
  }

  .paddock-col {
    padding-left: 0;
    border-right: solid thin #cbdbef;
  }

  .dry-matter-palette-container {
    left: -0.25rem;
    bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;
    max-width: 20rem;
  }

  .onload-branding-modal .modal-body {
    height: 532px !important;
    max-height: 532px !important;
  }

  .grazing-modal {
    max-width: 372px !important;
  }

  .modal-wording1 {
    font-size: 14px !important;
  }

  .modal-wording2 {
    font-size: 14px !important;
  }

  .modal-reminder-body {
    font-size: 14px !important;
  }

  .modal-reminder-list li {
    font-size: 14px !important;
  }
}

/* For Tablets(Landscape)*/
@media only screen and (width: 1024px) {
  body {
    font-size: 0.75rem !important;
  }

  .map-column {
    padding-left: 0px !important;
  }

  .average-heading {
    font-size: 1rem;
  }

  .average-heading strong {
    font-size: 1.5rem;
  }
  .map-column {
    padding-right: 0 !important;
  }
}

/* For Tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #root {
    font-size: 0.75rem;
  }

  .logo_image {
    max-width: 5.1875rem;
  }

  .space_image {
    max-width: 4.5rem;
    margin-top: 0.3rem;
  }

  .menu-button svg {
    height: 1.2rem;
  }

  .header {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    height: 3.5rem;
    border-bottom: solid thin #cbdbef;
  }

  .app-container [class*="col"] {
    padding: 0px 0px;
  }

  .name-heading,
  .average-heading strong {
    font-size: 1.125rem;
  }

  .average-heading {
    font-size: 0.75rem;
  }

  .farm-name {
    font-size: 0.875rem;
  }

  .paddock-tab .content-container {
    height: calc(93vh - 10rem);
  }

  .tab-content,
  .tab-pane {
    height: calc(100vh - 10rem);
  }

  .app-container {
    grid-template-areas: "branding" "header" "tab-bar" "content";
  }
}

/* For mobile */
@media only screen and (max-width: 767px) {
  #root {
    font-size: 0.75rem;
  }

  .app-container {
    grid-template-areas: "header" "tab-bar" "content" "branding";
    grid-template-rows: 3.5rem 3rem 1fr;
  }

  .app-container [class*="col"] {
    padding: 0px 0px !important;
  }

  .tab-bar {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "maps-button feed-wedge-button paddocks-button";
  }

  .header {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    height: 3.5rem;
    border-top: 0 !important;
  }

  .name-heading,
  .average-heading strong {
    font-size: 1.125rem;
    color: #001e62;
  }

  .farm-name,
  .average-heading {
    font-size: 0.875rem;
    color: rgba(0, 29, 97, 0.75);
  }

  .logo_image {
    max-width: 4.1875rem;
    height: 1rem;
  }

  .space_image {
    max-width: 5rem;
    height: 1rem;
  }

  .branding .navbar [class*="col"] {
    padding: 0.5rem;
  }

  .paddock-tab .content-container {
    height: calc(100% - 10rem);
  }

  .content-container,
  .tab-content,
  .tab-pane {
    height: calc(100% - 8.5rem);
    position: fixed;
    width: 100%;
  }

  .tab-pane > div {
    position: relative;
  }

  .layers-icon {
    top: 1rem !important;
  }
  .help-icon {
    top: 1rem !important;
  }
  .branding {
    height: 2rem;
    padding: 0.5rem;
    border-top: solid thin #cbdbef;
  }

  .paddock-tab-name input {
    font-size: 0.8rem;
  }

  .overlay-menu {
    width: 100%;
  }

  .mobile-row-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mobile-header-fix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .mobile-footer-fix {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .tab-content > .active {
    left: 0;
    right: 0;
  }
}
