.layers-icon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  left: 1rem;
  top: 4rem;
  border-radius: 12%;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;
}

.layers-icon button {
  color: #001e62;
  background-color: white;
  padding: 0.5rem 0rem;
  border-color: #001e62 !important;
}

.layers-icon button:hover,
.layers-icon button:active,
.layers-icon button:focus {
  color: #001e62 !important;
  background-color: #c8dbf1 !important;
  border-color: #001e62 !important;
}

.layers-icon svg {
  height: 1.5rem;
}

.help-icon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  left: 5rem;
  top: 4rem;
  border-radius: 12%;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;
}

.help-icon a {
  color: #001e62;
  background-color: white;
  padding: 0.7rem 0.7rem;
  border-color: #001e62 !important;
}

.help-icon svg {
  height: 1.5rem;
}
.help-icon a:hover,
.help-icon a:active,
.help-icon a:focus {
  color: #001e62 !important;
  background-color: #c8dbf1 !important;
  border-color: #001e62 !important;
}
