@font-face {
  font-family: "Booster FY";
  src: url("../node_modules/@licnz/styleguide/dist/fonts/BoosterNextFY-Regular.eot");
  src: url("../node_modules/@licnz/styleguide/dist/fonts/BoosterNextFY-Regular.ttf")
      format("truetype"),
    url("../node_modules/@licnz/styleguide/dist/fonts/BoosterNextFY-Regular.woff")
      format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Booster FY";
  src: url("../node_modules/@licnz/styleguide/dist/fonts/BoosterNextFY-Medium.eot");
  src: url("../node_modules/@licnz/styleguide/dist/fonts/BoosterNextFY-Medium.ttf")
      format("truetype"),
    url("../node_modules/@licnz/styleguide/dist/fonts/BoosterNextFY-Medium.woff")
      format("woff");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: "Booster FY", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
