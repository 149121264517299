.earth {
  overflow: visible;
  margin: auto;
  border: 0.1rem solid #001e62;
  border-radius: 8rem;
  width: 3rem;
  height: 3rem;
  background: url("./loading-earth-map.svg");
  background-size: 6rem 3rem;
  background-repeat: repeat-x;
  animation-name: rotate-background;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate-background {
  0% {
    background-position-x: -3rem;
  }
  100% {
    background-position-x: 3rem;
  }
}

.satellite {
  position: relative;
  height: 2rem;
  width: 2rem;
  transform: rotate(45deg);
  transform-origin: 1.5rem 1.5rem;
  animation-name: rotate-around-earth;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate-around-earth {
  0% {
    transform: rotate(45deg);
  }
  12.5% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(135deg);
  }
  37.5% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(225deg);
  }
  62.5% {
    transform: rotate(270deg);
  }
  75% {
    transform: rotate(315deg);
  }
  87.5% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

.satellite-body {
  position: relative;
  top: -0.75rem;
  left: -0.75rem;
  height: 1rem;
  width: 1rem;
  fill: #001e62;
}

.loading-text {
  font-weight: bold;
  font-size: 1.125rem;
  width: 10rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: auto;
  text-align: center;
  color: #001e62;
}
