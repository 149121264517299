.feedWedgeWrapper {
  height: 100%;
  overflow-y: scroll;
}

.row-container {
  margin: 20px 40px;
}

.grazing-unit {
  margin-left: 10px;
}

.align-description {
  text-align: left;
  line-height: 40px;
  padding-left: 15% !important;
  padding-right: 10px !important;
}

.align-control {
  text-align: left;
  padding-left: 10px;
  line-height: 40px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ant-switch {
  width: 45px;
}

.ant-switch-checked {
  width: 45px;
  background-color: #007bbc;
}

.hidden {
  display: none !important;
}

.feedWedgeLegend {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.feedWedgeLegendLeft {
  text-align: right;
  margin-right: 10px;
}

.feedWedgeLegendCenter {
  text-align: center;
  margin-right: 10px;
}

.feedWedgeLegendRight {
  text-align: left;
  margin-right: 10px;
}
.ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

/*Set style On screens that are 768px wide or less(maximum value for sm in bootstrap system ). For mobile */
@media screen and (max-width: 768px) {
  .align-description {
    text-align: left;
  }

  .align-control {
    text-align: left;
  }

  .row-container {
    margin: 1rem;
    width: 85% !important;
    padding-left: 30%;
  }

  .feedWedgeLegendLeft {
    text-align: center;
    margin-right: 10px;
  }

  .feedWedgeLegendRight {
    text-align: center;
    margin-right: 10px;
  }
}
