.static-font {
  font-size: 1rem;
  color: #001e62;
  margin-top: 7%;
  font-weight: bolder;
}

.onload-branding-modal {
  max-width: 272px !important;
}

.onload-branding-modal .modal-body {
  height: 432px;
  max-height: 432px;
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.onload-branding-modal h5 {
  color: #001e62;
  min-width: 93%;
  font-size: 14px;
  line-height: unset;
}

.onload-branding-modal h4 {
  font-size: 16px;
  padding: 8.5px;
  font-weight: bold;
  width: 240px;
  line-height: unset;
  margin-bottom: 18px;
}

.onload-branding-modal .predicted-heading {
  background-color: #fbe3fa;
  color: #de12d4;
}

.onload-branding-modal .satellite-heading {
  background-color: #e5eeff;
  color: #203a70;
}

.onload-branding-modal p,
.grazing-modal p {
  font-size: 10px;
  color: #001e62;
}

.modal-wording1 {
  margin-bottom: 19px;
  width: 238px;
}

.modal-wording2 {
  margin-top: 22px;
  margin-bottom: 10px;
}

.onload-branding-modal .report-button,
.report-button:hover,
.report-button:focus,
.report-button:active {
  padding: 12.5px;
  background-color: #203a70 !important;
  font-size: 21px;
  height: 71px;
}

.modal-logo {
  align-items: center;
  margin-bottom: unset;
  margin-right: unset !important;
  margin-left: unset !important;
}

.modal-logo img {
  max-height: 30px;
}

.modal-logo div:nth-last-child(1) {
  padding: unset;
}

.modal-logo div:nth-last-child(1) img {
  max-width: 150px;
}

.modal-logo1 {
  margin-bottom: 10px;
}

.modal-logo1 img {
  height: 25px;
}

.close-modal-icon {
  float: right;
}

.grazing-modal {
  max-width: 272px;
}

.grazing-modal button {
  background-color: #203a70 !important;
}

.modal-reminder-body {
  font-size: 0.75rem;
  margin-bottom: 2px;
}

.modal-reminder-header {
  font-size: 1.125rem !important;
  font-weight: bold;
}

.modal-reminder-list {
  padding-left: 20px;
}

.modal-reminder-list li {
  text-align: left;
  font-size: 0.7rem;
}

.modal-reminder-button {
  height: 38px !important;
}
