.left-label {
  padding: 0px !important;
}

.right-label {
  text-align: right;
  padding: 0px !important;
}

.map-layer-switch-container {
  width: 70%;
  z-index: 99;
}

.map-layer-color-palette {
  border: 1px solid black;
  min-height: 1.5rem;
  margin-left: 0rem;
  margin-right: 0rem;
}

.map-layer-switch-container .row {
  margin-left: 0rem;
  margin-right: 0rem;
}

.layers-menu {
  width: 100%;
  height: 7rem;
  padding: 1rem;
  background-color: white;
  margin-bottom: 1rem;
}

.layers-menu_h5 {
  width: 100%;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: 0 0 1rem 0;
  text-align: left;
}
.layers-menu_h5_button {
  height: 0.75rem;
  width: 0.75rem;
  background-color: transparent;
  float: right;
  border: none;
  border-radius: 50%;
  padding: 0;
  background: url(./close-icon.svg);
}

.layer-toggle-container {
  display: flex;
  justify-items: left;
}

.layer-toggle {
  margin: 0 1rem;
  padding: auto;
  text-align: center;
}

.layer-toggle_button {
  width: 3rem;
  height: 3rem;
  margin: 0 auto;
  border-radius: 0.25rem;
  border: thin solid black;
}

.layer-toggle_button.active {
  border: 0.25rem solid #139ade;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
}
.layer-legend {
  border-top: thin solid #cbdbef;
  padding: 0.5rem;
}

.layer-toggle_title {
  display: block;
}

.layer-legend .col-lg-12 {
  padding-left: 0;
  padding-right: 0;
}

.layer-print-button {
  margin-left: 0.4rem;
  background-color: #001e62 !important;
  width: fit-content;
  max-height: 2.7rem;
  padding: 0.2rem 0.2rem !important;
}

.print-icon {
  margin-top: 0.6rem;
  width: inherit;
}

.print-icon-text {
  line-height: 1.1rem;
}

.print-icon-text span {
  font-size: 0.75rem;
}

/* Use CSS instead of generating colour scale from colorScale in domain/constants.ts
   because it does not work in EDGE
*/
.dry-matter-color-scale {
  background: linear-gradient(
    to right,
    rgb(128, 128, 128) 0%,
    rgb(128, 128, 128) 2.85714%,
    rgb(255, 14, 0) 2.85714%,
    rgb(255, 14, 0) 5.71429%,
    rgb(255, 27, 0) 5.71429%,
    rgb(255, 27, 0) 8.57143%,
    rgb(255, 41, 0) 8.57143%,
    rgb(255, 41, 0) 11.4286%,
    rgb(255, 51, 0) 11.4286%,
    rgb(255, 51, 0) 14.2857%,
    rgb(255, 69, 0) 14.2857%,
    rgb(255, 69, 0) 17.1429%,
    rgb(255, 83, 0) 17.1429%,
    rgb(255, 83, 0) 20%,
    rgb(255, 97, 0) 20%,
    rgb(255, 97, 0) 22.8571%,
    rgb(255, 112, 0) 22.8571%,
    rgb(255, 112, 0) 25.7143%,
    rgb(255, 126, 0) 25.7143%,
    rgb(255, 126, 0) 28.5714%,
    rgb(255, 141, 0) 28.5714%,
    rgb(255, 141, 0) 31.4286%,
    rgb(255, 155, 0) 31.4286%,
    rgb(255, 155, 0) 34.2857%,
    rgb(255, 169, 0) 34.2857%,
    rgb(255, 169, 0) 37.1429%,
    rgb(255, 184, 0) 37.1429%,
    rgb(255, 184, 0) 40%,
    rgb(255, 212, 0) 40%,
    rgb(255, 212, 0) 42.8571%,
    rgb(255, 226, 0) 42.8571%,
    rgb(255, 226, 0) 45.7143%,
    rgb(255, 240, 0) 45.7143%,
    rgb(255, 240, 0) 48.5714%,
    rgb(255, 254, 0) 48.5714%,
    rgb(255, 254, 0) 51.4286%,
    rgb(241, 246, 0) 51.4286%,
    rgb(241, 246, 0) 54.2857%,
    rgb(226, 238, 0) 54.2857%,
    rgb(226, 238, 0) 57.1429%,
    rgb(212, 230, 0) 57.1429%,
    rgb(212, 230, 0) 60%,
    rgb(198, 221, 0) 60%,
    rgb(198, 221, 0) 62.8571%,
    rgb(184, 213, 0) 62.8571%,
    rgb(184, 213, 0) 65.7143%,
    rgb(170, 205, 0) 65.7143%,
    rgb(170, 205, 0) 68.5714%,
    rgb(156, 196, 0) 68.5714%,
    rgb(156, 196, 0) 71.4286%,
    rgb(142, 188, 0) 71.4286%,
    rgb(142, 188, 0) 74.2857%,
    rgb(127, 180, 0) 74.2857%,
    rgb(127, 180, 0) 77.1429%,
    rgb(112, 171, 0) 77.1429%,
    rgb(112, 171, 0) 80%,
    rgb(98, 163, 0) 80%,
    rgb(98, 163, 0) 82.8571%,
    rgb(84, 155, 0) 82.8571%,
    rgb(84, 155, 0) 85.7143%,
    rgb(70, 146, 0) 85.7143%,
    rgb(70, 146, 0) 88.5714%,
    rgb(56, 138, 0) 88.5714%,
    rgb(56, 138, 0) 91.4286%,
    rgb(41, 130, 0) 91.4286%,
    rgb(41, 130, 0) 94.2857%,
    rgb(27, 120, 0) 94.2857%,
    rgb(27, 120, 0) 97.1429%,
    rgb(14, 112, 0) 97.1429%,
    rgb(14, 112, 0) 100%
  );
}

.cloud-shadow-color-scale {
  background: linear-gradient(
    to right,
    rgb(0, 0, 0) 0%,
    rgb(0, 0, 0) 50%,
    rgb(0, 200, 0) 50%,
    rgb(0, 200, 0) 100%
  );
}

/* For Desktop & tablet */
@media only screen and (min-width: 768px) {
  .map-layer-switch-container {
    z-index: 10;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    background: white;
    width: 22rem;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 3px;
  }
}

/* For mobile only */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .map-layer-switch-container {
    position: fixed;
    bottom: 2rem;
    background-color: white;
    margin-left: 10px;

    right: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 2px -3px 3px;
  }

  .left-label {
    font-size: 0.75rem;
  }

  .right-label {
    font-size: 0.75rem;
  }
}
